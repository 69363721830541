import { Coordinator } from './assigned-users.model';
import { MarkerMap } from './marker-map.model';
import { User } from './user.model';

export enum Text {
  NA = 'N/A'
}

export type Agencies = {
  [key: number | string]: number | null | undefined;
};

export interface NewPermitForm {
  jobType: string;
  jobNumber: string;
  withoutJobNumber: boolean;
}

export interface DetailsForm {
  budgetCode: string;
  workOrderNo: string;
  projectNumber: string;
  projectType: string;
  workType: string;
  outageRequired: string;
  jobTitle: string;
  agencies: Agencies;
}

export interface ContactsForm {
  watchers: {
    [elementKey: number]: User | null | undefined;
  };
}

export interface ScheduleForm {
  constructionStartDate: Date | null;
  constructionEndDate: Date | null;
  cmpDueDate: string | null;
  timeOfWork: string | null;
  workStartTime: Date | null;
  workEndTime: Date | null;
}
export interface ScopeForm {
  customersPermitNo: string;
  jobDescription: string;
  customerJobDescription: string;
  scopeDescription: string;
}

export interface LocationForm {
  primaryAddress: MarkerMap | null;
  thomsBros: string;
  supportingAddresses: MarkerMap[];
}

export interface PermitDetailsForm {
  customersPermitNo: string;
  cost: string;
  approved: Date | null;
  expires: Date | null;
  agencies: Agencies;
  projectType: string;
  workType: string;
  budgetCodeDescription: string;
  budgetCode: string | null;
  outageRequired: string;
  isAnnualPermit: boolean | undefined;
  constructionChangeNumber: string | null;
}

export interface ScopeInterface extends ScopeForm {
  isNoChecked: boolean | undefined;
  isCustomerChecked: boolean | undefined;
  isSdgeChecked: boolean | undefined;
}

export type ReassignmentForm = {
  qaqc: Coordinator | null;
  pss: Coordinator | null;
  tcp: Coordinator | null;
  ts: Coordinator | null;
  ta: Coordinator | null;
};
